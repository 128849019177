import { GetStaticProps } from 'next'

import { getPageSettings } from '../utils/api'

import { PageSettings } from '../@types/PageSettings'

import { PageProps, PageType } from '../@types/Page'

import Layout from '../src/layouts/Layout'

import Custom404 from '../src/components/Custom404'

const Custom404Page: React.FC<PageProps> = ({ settings }) => {
  return (
    <Layout
      settings={settings}
      content={{
        title: '404',
        slug: '',
        meta: { tags: '', schema: '' },
        type: PageType.Page,
        link: '',
        sections: [],
        tabfilter: { has_filter: false, filter: [] },
      }}
    >
      <Custom404 settings={settings} />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const settings: PageSettings = await getPageSettings()

  return {
    props: {
      settings,
    },
  }
}

export default Custom404Page
