import React from 'react'

import { Typography, Link } from '@material-ui/core'

import { PageSettings } from '../../../@types/PageSettings'

import IconArrow from '../../../public/icons/icon-arrow.svg'

import useStyles from './Custom404.styles'

type Props = {
  settings: PageSettings
}

const Custom404: React.FC<Props> = ({ settings }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography
        variant="h1"
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: settings?.errorPage?.headline }}
      />

      <div className={classes.descriptionWrap}>
        <Typography component="p" className={classes.description}>
          {settings?.errorPage?.copy}
        </Typography>
      </div>

      {settings?.errorPage?.link && (
        <Link
          href="/"
          target={settings?.errorPage?.link.type === 'external' ? '_blank' : undefined}
          className={classes.link}
        >
          <Typography className={classes.linkLabel}>{settings?.errorPage?.link.label}</Typography>
          <IconArrow className={classes.arrowIcon} />
        </Link>
      )}
    </div>
  )
}

export default Custom404
