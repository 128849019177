import { makeStyles } from '@material-ui/core'

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 68,
      paddingLeft: 35,
      paddingRight: 35,
      paddingTop: 60,
      marginTop: 65,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 88,
        paddingTop: 80,
        marginTop: 71,
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: 146,
        paddingTop: 100,
        marginTop: 136,
      },
    },

    title: {
      fontSize: 30,
      lineHeight: '30px',
      letterSpacing: 2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#000',
      textAlign: 'center',
      width: '100%',
      maxWidth: 650,
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
        lineHeight: '42px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 64,
        lineHeight: '64px',
        letterSpacing: 4,
      },

      '& p': {
        margin: 0,
        paddin: 0,
      },
    },

    descriptionWrap: {
      margin: '20px 0 12px 0',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        margin: '24px 0 20px 0',
        width: 470,
      },
      [theme.breakpoints.up('lg')]: {
        margin: '29px 0 20px 0',
        width: 619,
      },
    },

    description: {
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.5,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: '24px',
      },
    },

    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '9px 0',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',

        '& $arrowIcon': {
          marginLeft: 18,
        },
      },
    },

    linkLabel: {
      fontFamily: 'ProximaNova-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: '0.5px',
      color: theme.palette.common.black,
    },

    arrowIcon: {
      width: 24,
      height: 24,
      fill: theme.palette.common.black,
      marginLeft: 9,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  }),
  { name: 'Custom404' }
)
